import React from 'react';
import { Footer, SEO, Layout } from "@components"
import Container from "@components/container"
import Headline from "@components/pages/home/Pricing/Headline"
import Table from "@components/pages/home/Pricing/Table"
import MoreQuestions from "@components/pages/home/Pricing/MoreQuestions"
import Testimonials from "@components/pages/home/Testimonials"
import AdditionalTaxes from "@components/pages/buy/AdditionalTaxes"
import { graphql } from "gatsby"
import TestimonialsArrow from "../components/pages/home/TestimonialsArrow"

const BuyPage = ({ data }) => {
  return (
    <>
      <Layout showParityOffer hidePreheaderCTA>
        <SEO
          title="Purchase Dynobase"
          description="Purchase Dynobase"
          noIndex
        />
        <Container>
          <Headline />
        </Container>
        <AdditionalTaxes />
        <Container>
          <Table customMt={null} />
          <TestimonialsArrow />
          <Testimonials customMt={[72]} data={data} />
          <MoreQuestions customHeadline="Have a question?" />
        </Container>
        <Footer />
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allImageSharp {
      nodes {
        gatsbyImageData(width: 800)
        fluid {
          originalName
        }
      }
    }
  }
`

export default BuyPage
