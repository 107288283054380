import React from "react"

export default () => (
  <div
    className="testimonials-arrow-container"
    style={{
      position: "absolute",
      marginLeft: "-60px",
    }}
  >
    <p
      style={{
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: 1,
        marginBottom: "12px",
      }}
    >
      Not convinced yet?
    </p>
    <p
      style={{
        fontSize: "20px",
        lineHeight: "0",
      }}
    >
      See what others think.
    </p>
    <svg
      className="testimonial-arrow"
      x={0}
      y={0}
      viewBox="0 0 43.1 85.9"
      // style="enable-background:new 0 0 43.1 85.9;"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        className="st0 draw-arrow"
        d="M11.3,2.5c-5.8,5-8.7,12.7-9,20.3s2,15.1,5.3,22c6.7,14,18,25.8,31.7,33.1"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        className="draw-arrow tail-1"
        d="M40.6,78.1C39,71.3,37.2,64.6,35.2,58"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        className="draw-arrow tail-2"
        d="M39.8,78.5c-7.2,1.7-14.3,3.3-21.5,4.9"
      />
    </svg>
  </div>
)
